<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="标准名称" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联商品" prop="is_bind">
          <el-select v-model="table.params.is_bind" placeholder="请选择" clearable @change="onSearch">
            <el-option label="已关联" :value="1"></el-option>
            <el-option label="未关联" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="dialogVisible = true">导入</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="selGoods(row)">关联商品</el-button>
      </template>
    </VTable>

    <el-dialog title="导入" :visible.sync="dialogVisible" width="460px" :before-close="handleClose">
      <el-form :model="form" ref="elFormDom" :rules="form.rules" label-position="top">
        <el-form-item>
          <a :href="sampleFile">
            <el-button type="primary" plain>下载样表</el-button>
          </a>
        </el-form-item>
       
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            action=""
            :headers="{token:token}"
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            :http-request="httpRequestBack"
            accept=".xls,.xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="导入商品标名反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      >
        <div style="font-weight: bold;">导入成功：{{templateDialog.success}} 条</div>
        <div v-if="templateDialog.errors.length > 0" class="errorBox">
          <p v-for="(item, index) in templateDialog.errors" :key="index">{{index +1}}、{{item}}</p>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 绑定关联商品 -->
    <SelGoods :dialogTitle="dialogTitle" ref="selGoods" @refresh="getTable"></SelGoods>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import SelGoods from './components/SelGoods.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'NormmIndex',
  components: {
    Edit,
    SelGoods,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "name", label: "标准名称", hidden: false },
        { name: "goods_count", label: "关联商品数量", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      sampleFile: '', // 样表
      table: {
        loading: false,
        params: {
          keyword: '',
          is_bind: '', //是否关联商品 1:是 2:否
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      dialogTitle: '关联商品',
      form: {
        loading: false,
        data: {
          curfile:'',
        },
      },
      dialogVisible: false,
      fileList: [],
      // 导入后提示
      errorDialogVisible: false,  // 导入异常弹窗
      templateDialog: {
        success: 0,
        errors: [],
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/norm/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.sampleFile = res.data.file;// 样表
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    //负者供货商品
    selGoods(row) {
      let dom = this.$refs.selGoods;
        dom.toggle(true)
        dom.getDetail(row)
        dom = null
    },
    httpRequestBack(file) {
      this.curfile = file.file
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          if(this.curfile) {
            let formData = new FormData();
            formData.append('file', this.curfile);
            this.form.loading = true;
            this.$http.post('/admin/norm/import', {formData, type:'upload'}).then(res => {
              if(res.code == 1) {
                this.errorDialogVisible = true;
                this.templateDialog.errors = res.data.errors;
                this.templateDialog.success = res.data.success;
              }
            }).finally(() => {
              this.form.loading = false;
              this.handleClose();
            })
          } else {
            this.$message.warning('请选择要导入的文件')
          }
        }
      })
    },
    removeFile() {
      this.fileList = [];
    },
    // 关闭导入弹窗
    handleClose() {
      this.dialogVisible = false;
      this.fileList = [];
      this.curfile = '';
    },
    // 关闭异常
    errorClose() {
      this.errorDialogVisible = false
      this.getTable();
    },
  }
}
</script>
<style scoped>
.errorBox {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
